exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-curriculum-js": () => import("./../../../src/templates/curriculum.js" /* webpackChunkName: "component---src-templates-curriculum-js" */),
  "component---src-templates-direction-js": () => import("./../../../src/templates/direction.js" /* webpackChunkName: "component---src-templates-direction-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-photos-js": () => import("./../../../src/templates/photos.js" /* webpackChunkName: "component---src-templates-photos-js" */),
  "component---src-templates-videobook-js": () => import("./../../../src/templates/videobook.js" /* webpackChunkName: "component---src-templates-videobook-js" */)
}

