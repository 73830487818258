module.exports = {
  chooseLanguage: 'Tria idioma',
  'languages.es': 'Castellà',
  'languages.ca': 'Català',
  'mainMenu.cv': 'Actor',
  'mainMenu.direction': 'Director de Teatre',
  'mainMenu.photos': 'Fotos',
  'mainMenu.videobook': 'Videobook',
  'mainMenu.contact': 'Contacte',
  'mainMenu.events': 'Events',
  'footer.downloadCv': 'Descarregar CV (PDF)',
  'footer.downloadDirectorCv': 'Descarregar CV com a director (PDF)',
  'curriculum.education': 'Estudis',
  'curriculum.teacher': 'Professor/a',
  'curriculum.director': 'Director',
  'curriculum.author': 'Autor',
  'curriculum.years': 'Anys',
  'curriculum.company': 'Companyia',
  'curriculum.production': 'Productora',
  'contact.fields.email.label': 'El teu email',
  'contact.fields.name.label': 'El teu nom',
  'contact.fields.subject.label': 'Assumpte de la teva consulta',
  'contact.fields.comments.label': 'Comentaris',
  'contact.fields.sending': 'Enviant missatge...',
  'contact.fields.send': 'Enviar missatge',
  'contact.fields.email.invalidFormat': 'Email no vàlid',
  'contact.messageSent': 'Missatge enviat',
  'contact.thanks':
    'Gràcies {name}, com el rebi et contesto al teu email: {email}',
}
