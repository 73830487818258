module.exports = {
  chooseLanguage: 'Elige idioma',
  'languages.es': 'Castellano',
  'languages.ca': 'Catalán',
  'mainMenu.cv': 'Actor',
  'mainMenu.direction': 'Director de Teatro',
  'mainMenu.photos': 'Fotos',
  'mainMenu.videobook': 'Videobook',
  'mainMenu.contact': 'Contacto',
  'mainMenu.events': 'Events',
  'footer.downloadCv': 'Descargar CV (PDF)',
  'footer.downloadDirectorCv': 'Descargar CV como director (PDF)',
  'curriculum.education': 'Educación',
  'curriculum.teacher': 'Profesor/a',
  'curriculum.director': 'Director',
  'curriculum.author': 'Autor/a',
  'curriculum.years': 'Años',
  'curriculum.company': 'Companñía',
  'curriculum.production': 'Productora',
  'contact.fields.email.label': 'Tu email',
  'contact.fields.name.label': 'Tu nombre',
  'contact.fields.subject.label': 'Asunto de tu consulta',
  'contact.fields.comments.label': 'Comentarios',
  'contact.fields.sending': 'Enviando mensaje...',
  'contact.fields.send': 'Enviar mensaje',
  'contact.fields.email.invalidFormat': 'Email no válido',
  'contact.messageSent': 'Mensaje enviado',
  'contact.thanks':
    'Gracias {name} en cuanto lo reciba te contesto a tu email: {email}',
}
